import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import "../../styles/gptHeader.scss";
import { Link, useNavigate } from "react-router-dom";
import { defaultUser, logo_main } from "../../assets/images";
import { useAuth } from "../../context/userContext";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { logout } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
const GptHeader = () => {
  const [openToggler, setOpenToggler] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth] = useAuth(); // GET USER DETAILS FROM USER CONTEXT

  // FOR LOGIN PAGE CHANGE IN ROUTING
  const handleSignInPage = () => {
    navigate("/login");
  };

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  // console.log(auth);
  return (
    <div className="gpt_header_main">
      <div className="container">
        <div className="nav_bar_sec">
          <div className="logo">
            <Link to="/">
              <img
                className="desktop"
                src={logo_main}
                alt="Alpha Regiment desktop_logo"
              />
             
            </Link>
          </div>
          <div className="open_navbar_btn">
            <GiHamburgerMenu onClick={() => setOpenToggler(!openToggler)} />
          </div>
          <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
            <div className="close_menu_btn">
              <AiOutlineClose onClick={() => setOpenToggler(!openToggler)} />
            </div>

            <div className="user_details_nav_link_mobile">
              <div className="userDetails">
                <div className="profileImg">
                  <img src={defaultUser} alt="user" />
                </div>
                <div className="userName">{auth?.user?.first_name}</div>
              </div>
            </div>

            <div className="nav_link dropdown_nav">
              {auth?.user?._id ? (
                <div className="auth_profile_dropdown">
                  <button
                    className={`sign_up_btn dashboard_btn ${
                      auth?.user?.first_name ? "profile_header_btn" : ""
                    }`}
                    onClick={() => setUserDropdown(!userDropdown)}
                  >
                    <img
                      src={
                        auth?.user?.profile?.url
                          ? auth?.user?.profile?.url
                          : defaultUser
                      }
                      alt={auth?.user?.first_name}
                    />
                    {auth?.user?.first_name?.slice(0, 10)}
                    {userDropdown ? (
                      <BsFillCaretUpFill />
                    ) : (
                      <BsFillCaretDownFill />
                    )}
                  </button>
                  <div
                    className={`nav_dropdown_links ${
                      userDropdown ? "show_nav_dropdown" : ""
                    }`}
                  >
                    {/* <Link to="/">Profile</Link> */}
                    <button className="logout_btn" onClick={handleLogout}>
                      logout
                    </button>
                  </div>
                </div>
              ) : (
                <div className="headerSign_btns">
                  <button className="sign_up_btn" onClick={handleSignInPage}>
                    Sign In
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GptHeader;
