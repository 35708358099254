import React, { useEffect, useState } from "react";
import "../../styles/gptSidebar.scss";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
const GptSidebar = ({ setOutputData }) => {
  const [questionList, setQuestionList] = useState([]);
  const [viewHistory, setViewHistory] = useState(false);
  const savedList = localStorage.getItem("userHistory");
  useEffect(() => {
    if (savedList) {
      setQuestionList(JSON.parse(savedList));
    }
  }, [savedList]);

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  const [openIndex, setOpenIndex] = useState();
  const handleView = (id) => {
    setViewHistory(!viewHistory);
    setOpenIndex(id);
  };

  return (
    <section className="gpt_sidebar">
      <h2>Search History</h2>
      {questionList?.length > 0 ? (
        <ul>
          {questionList?.map((ele, idx) => (
            <li key={idx} onClick={() => handleView(idx)}>
              <p>
                {ele?.dayData}
                {!viewHistory ? <RiArrowDropDownLine /> : <RiArrowDropUpLine />}
              </p>
              {viewHistory && openIndex === idx && (
                <span onClick={() => handleShowHistory(ele?.dayData)}>
                  {ele?.searchData?.map((item, id) => (
                    <p key={id}>
                     {
                      item?.question &&
                      `${id  + 1}. ${item?.question}`
                     }
                      {
                      item?.srtList?.length > 0 &&
                      `${id  + 1}. Set Of SRT`
                     }
                    </p>
                  ))}
                </span>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No search history found</p>
      )}
    </section>
  );
};

export default GptSidebar;
