import React, { Fragment } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import {  logo_main } from "../assets/images";
import GptLayout from "../component/ssbGpt/gptLayout";
import CustomHelmet from "../component/reusable/Helmet";

const TermsCondition = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="NDA-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <section className="privacy_policy_main txt_color ">
          <div className="w_bg">
            <div className="top_bar">
              <div className="go_back">
                <Link onClick={() => navigate(-1)}>
                  <AiOutlineArrowLeft />
                </Link>
                <p>Terms of Use for NDA GPT</p>
              </div>
             
            </div>

            <section className="policy_layout_main">
              <div className="profile_content">
                <div className="privacy_content">
                  <div className="title">
                  <img src={logo_main} alt="logo" />
                    <p>Last updated: July 15, 2024</p>
                  </div>
                  <div className="privacy_text">
                    <h2 className="heading">1. Acceptance of Terms</h2>
                    <p>
                      By accessing or using SSB GPT, you agree to be bound by
                      these Terms of Use. If you do not agree to these terms,
                      please do not use the service.
                    </p>
                    <h2 className="heading">2. Description of Service</h2>
                    <p>
                      SSB GPT is an AI-powered chatbot designed to provide
                      information and assistance related to the Service
                      Selection Board (SSB) process and general defence-related
                      queries. The service is intended for informational
                      purposes only.
                    </p>

                    <h2 className="heading">3. User Eligibility</h2>
                    <p>
                      You must be at least 14 years old to use SSB GPT. If you
                      are under 14, you may only use the service under the
                      supervision of a parent or legal guardian.
                    </p>

                    <h2 className="heading">4. Intellectual Property Rights</h2>
                    <p>
                      All content provided by SSB GPT, including but not limited
                      to text, graphics, logos, and software, is the property of
                      the service provider and is protected by applicable
                      copyright laws.
                    </p>

                    <h2 className="heading">5. User Conduct</h2>
                    <p>
                      You agree to use SSB GPT only for lawful purposes and in a
                      manner that does not infringe upon or restrict others' use
                      and enjoyment of the service. Prohibited activities
                      include:
                    </p>
                    <span>
                      a) Attempting to gain unauthorized access to the system
                    </span>
                    <span>
                      b) Transmitting any unlawful, harmful, or offensive
                      content
                    </span>
                    <span>c) Impersonating any person or entity</span>
                    <span>
                      d) Using the service for any commercial purposes without
                      explicit permission
                    </span>

                    <h2 className="heading">6. Disclaimer of Warranties</h2>
                    <p>
                      SSB GPT is provided "as is" without any warranties,
                      express or implied. The service provider does not
                      guarantee the accuracy, completeness, or reliability of
                      any information provided by the chatbot.
                    </p>

                    <h2 className="heading">7. Limitation of Liability</h2>
                    <p>
                      The service provider shall not be liable for any direct,
                      indirect, incidental, consequential, or punitive damages
                      arising from your use of SSB GPT.
                    </p>

                    <h2 className="heading">8. Security and Confidentiality</h2>
                    <span>
                      a) Do not share any classified or sensitive
                      defense-related information with SSB GPT.
                    </span>
                    <span>
                      b) The service provider cannot guarantee the
                      confidentiality of information exchanged through the
                      chatbot.
                    </span>
                    <span>
                      c) Users are advised to exercise caution and discretion
                      when sharing personal information.
                    </span>

                    <h2 className="heading">9. No Official Endorsement</h2>
                    <p>
                      SSB GPT is not officially endorsed by or affiliated with
                      any government defense organization or the Service
                      Selection Board. Information provided should not be
                      considered as official guidance.
                    </p>
                    <h2 className="heading">10. Accuracy of Information</h2>
                    <p>
                      While SSB GPT strives to provide accurate and up-to-date
                      information, users should verify critical information from
                      official sources before making any decisions or taking
                      actions based on the chatbot's responses.
                    </p>
                    <h2 className="heading">11. No Substitute for Professional Advice</h2>
                    <p>
                      SSB GPT is not a substitute for professional advice. Users
                      should consult qualified professionals for specific
                      guidance related to their SSB preparation or
                      defense-related queries.
                    </p>
                    <h2 className="heading">12. Updates to Terms</h2>
                    <p>
                      The service provider reserves the right to modify these
                      Terms of Use at any time. Users are responsible for
                      regularly reviewing the terms for any changes.
                    </p>
                    <h2 className="heading">13. Termination of Service</h2>
                    <p>
                      The service provider reserves the right to terminate or
                      suspend access to SSB GPT at any time, without prior
                      notice or liability, for any reason.
                    </p>
                    <h2 className="heading">14. Governing Law</h2>
                    <p>
                      These Terms of Use shall be governed by and construed in
                      accordance with the laws of India, without regard to its
                      conflict of law provisions.
                    </p>
                    <h2 className="heading">15. User Data and Privacy</h2>
                    <span>
                      a) SSB GPT may collect and store user conversations for
                      service improvement purposes.
                    </span>
                    <span>
                      b) Personal information will be handled in accordance with
                      our Privacy Policy.
                    </span>
                    <span>
                      c) Users should not share any classified or sensitive
                      personal information through the chatbot.
                    </span>

                    <h2 className="heading">16. Feedback and Reporting</h2>
                    <p>
                      Users are encouraged to report any inaccuracies, offensive
                      content, or technical issues to the service provider
                      through the designated channels.
                    </p>
                    <h2 className="heading">17. No Guarantee of SSB Success</h2>
                    <p>
                      Using SSB GPT does not guarantee success in the SSB
                      process or selection for any defence services. The chatbot
                      is a supplementary tool and should not be considered a
                      replacement for thorough preparation.
                    </p>

                    <h2 className="heading">18. External Links</h2>
                    <p>
                      SSB GPT may provide links to external websites or
                      resources. The service provider is not responsible for the
                      content or reliability of these external sources.
                    </p>

                    <h2 className="heading">19. Language and Translations</h2>
                    <p>
                      The primary language of SSB GPT is English. While efforts
                      may be made to provide information in other languages, the
                      English version shall prevail in case of any
                      discrepancies.
                    </p>
                    <h2 className="heading">20. Severability</h2>
                    <p>
                      If any provision of these Terms of Use is found to be
                      unenforceable or invalid, that provision shall be limited
                      or eliminated to the minimum extent necessary so that the
                      Terms shall otherwise remain in full force and effect and
                      enforceable.
                    </p>
                    <p>
                      By using SSB GPT, you acknowledge that you have read,
                      understood, and agree to be bound by these Terms of Use.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <Link to="/" className="goHome_btn">
              Home
            </Link>
          </div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default TermsCondition;
