import React, { Fragment, useEffect, useState } from "react";
import "../styles/login.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { useAuth } from "../context/userContext";
import { clearErrors, login } from "../redux/actions/userAction";
import Loader from "../component/reusable/Loader";
import GptLayout from "../component/ssbGpt/gptLayout";
import CustomHelmet from "../component/reusable/Helmet";
import { signIn } from "../assets/images";
import CustomInput from "../component/reusable/CustomInput";

const Login = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [auth] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // destructure
  const { email, password } = loginData;
  // GEt User after trigger login function
  const { error, loading: loginLoading } = useSelector(
    (state) => state.userLogin
  );
  const location = useLocation();

  // REDIRECT URL
  let redirect = location.state && location.state;

  // console.log(location.state);
  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // Login Handler Function
  const handleLogin = () => {
    if (email && password) {
      dispatch(login(email, password));
    } else {
      toast.warning("Please fill all details carefully.");
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (auth?.user?._id && redirect) {
      navigate(redirect);
    } else if (auth?.user?._id) {
      navigate("/");
    }
  }, [navigate, redirect, location.state, auth?.user?._id]);

  return (
    <Fragment>
      {loginLoading ? (
        <Loader />
      ) : (
        <GptLayout>
          <CustomHelmet
            keywords="Login Alpha Regiment SSB-GPT"
            description="Welcome again, Alpha Soldier, let's complete today's stuff so that tomorrow we will be free to serve the motherland."
            author="Team Alpha Regiment SSB-GPT"
            title="Welcome again, Alpha Soldier. || Alpha Regiment SSB-GPT"
          />
          <section className="login_sec_main">
            <div className="container">
              <div className="login_form">
                <div className="mobileTitle">
                  <h1>SIGN IN</h1>
                </div>
                <div className="login_logo">
                  <img src={signIn} alt="signIn" />
                </div>
                <div className="login_form_inputs">
                  <div className="title">
                    <h1>SIGN IN</h1>
                  </div>
                  <CustomInput
                    label="Unique ID Or Email"
                    type="text"
                    placeholder="Ex: 5267711 / example@gmail.com"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    impStar="*"
                  />
                  <CustomInput
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    impStar="*"
                    eyeIcon={
                      showPassword ? <AiFillEyeInvisible /> : <AiFillEye />
                    }
                    setshowPassword={setshowPassword}
                  />
                  <button className="login_submit_btn" onClick={()=>handleLogin()}>
                    LOGIN
                  </button>
                  <div className="have_account">
                    <p>
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </p>
                    <p>
                      Don’t have an account?{" "}
                      <Link to="/register">Register</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default Login;
