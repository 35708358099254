import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import "../../styles/videoPlayer.scss";
import { MdEventNote } from "react-icons/md";
import { FaBackward, FaForward, FaPause, FaPlay } from "react-icons/fa";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
const styles = {
  playerWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  skipAnimation: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "16px",
    zIndex: "1",
    color: "white",
    transition: "transform 0.5s ease",
  },
  progressBar: {
    display: "none",
  },
  timer: {
    display: "none",
  },
};
const CustomVideoPlayer = () => {
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [notes, setNotes] = useState([]);
  const [isPlaying, setIsPlaying] = useState(true);
  const [skipTime, setSkipTime] = useState(null);
  const [tapCount, setTapCount] = useState(0);
  const [lastTapTime, setLastTapTime] = useState(0);

  const [searchParams] = useSearchParams();

  const streamId = searchParams.get("streamId");

  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };

  const handleSeek = (time) => {
    playerRef.current.seekTo(time);
    setCurrentTime(time);
  };

  const handleAddNote = () => {
    setIsAddingNote(!isAddingNote);
    playerRef.current?.seekTo(currentTime);
    setIsPlaying(!isPlaying);
    const addNotesDataElement = document.querySelector(".add_notes_data");
    if (addNotesDataElement) {
      addNotesDataElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  const handleSaveNote = () => {
    setIsAddingNote(false);
    const newNote = { time: currentTime, text: noteText };
    setNotes([...notes, newNote]);
    setNoteText("");
    setIsPlaying(true);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSkipBackward = () => {
    const newTime = Math.max(currentTime - 15, 0);
    handleSeek(newTime);
    setSkipTime(-15);
    setTimeout(() => {
      setSkipTime(null);
    }, 500);
  };

  const handleSkipForward = () => {
    const newTime = Math.min(currentTime + 15, duration);
    handleSeek(newTime);
    setSkipTime(15);
    setTimeout(() => {
      setSkipTime(null);
    }, 500);
  };

  const handleContainerClick = (e) => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastTapTime;

    if (timeDifference < 300 && tapCount === 1) {
      const rect = e.currentTarget.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;

      if (clickPosition <= rect.width / 2) {
        handleSkipBackward();
      } else {
        handleSkipForward();
      }

      setTapCount(0);
    } else {
      setTapCount(1);
      setLastTapTime(currentTime);
    }
  };

  const renderCustomControls = () => {
    return (
      <div className="audio_track_media">
        <div className="track_control">
          <button onClick={handleSkipBackward}>
            <IoIosSkipBackward />
          </button>
          <button onClick={togglePlayPause}>
            {!isPlaying ? <FaPlay /> : <FaPause />}
          </button>
          <button onClick={handleSkipForward}>
            <IoIosSkipForward />
          </button>
        </div>
        <div
          className="progress-bar"
          onClick={(e) => {
            const rect = e.target.getBoundingClientRect();
            const clickPosition = e.clientX - rect.left;
            const seekTime = (clickPosition / rect.width) * duration;
            handleSeek(seekTime);
          }}
        >
          {notes.map((note, index) => (
            <div
              key={index}
              className="note-dot"
              style={{ left: `${(note.time / duration) * 100}%` }}
            />
          ))}
          <div className="time-display">
            <span>{formatTime(currentTime)}</span> /{" "}
            <span>{formatTime(duration)}</span>
          </div>
        </div>
        <button onClick={handleAddNote}>
          <MdEventNote />
        </button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="back_btn">
        <button onClick={() => navigate(-1)}>Go back</button>
      </div>
      <div className="video-container" onClick={handleContainerClick}>
        <div style={styles.playerWrapper}>
          <ReactPlayer
            ref={playerRef}
            playing={isPlaying}
            url={`https://www.youtube.com/embed/${streamId}`}
            controls={false}
            onProgress={handleProgress}
            onDuration={handleDuration}
            // onClick={handleClickOnVideo()}
            width="100%"
            height="400px"
            minHeight="400px"
          />
          {skipTime !== null && (
            <div
              className="skip_animation"
              style={{
                ...styles.skipAnimation,
                left: skipTime < 0 ? "10%" : "85%",
              }}
            >
              <p>
                {skipTime < 0 && <FaBackward />}
                {Math.abs(skipTime)} sec
                {skipTime > 0 && <FaForward />}
              </p>
            </div>
          )}
          {renderCustomControls()}
        </div>

        {isAddingNote && (
          <div className="add_notes_data">
            <textarea
              value={noteText}
              rows={5}
              onChange={(e) => setNoteText(e.target.value)}
              placeholder="Add your note here..."
            />
            <button className="note_btn" onClick={handleSaveNote}>
              Save Note
            </button>
          </div>
        )}
        {notes?.length > 0 && (
          <div className="added_notes">
            <h3>Notes:</h3>
            <ul>
              {notes.map((note, index) => (
                <li key={index}>
                  <p>{formatTime(note.time)}</p>
                  <div>
                    <p>{note.text}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
