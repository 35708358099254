import axios from "axios";

const localStorageToken = localStorage.getItem("ssb-token")
  ? JSON.parse(localStorage.getItem("ssb-token"))
  : "";
  
let token = localStorageToken?.token;


class CommonService {
  async get(url) {
    return new Promise(async function (resolve, reject) {
      await axios({
        method: "get",
        url: url,
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              alert(err?.response?.message);
              localStorage.removeItem("cacheData");
            } else if (err.response.status === 404) {
              console.log(err?.response?.message);
            } else {
            console.log(err);
            }
          } else {
          console.log(err);
          }
        });
    });
  }

  async delete(url) {
    return new Promise(async function (resolve, reject) {
      await axios({
        method: "delete",
        url: url,
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  async post(url, data) {
    return new Promise(async function (resolve, reject) {
      await axios({
        method: "post",
        url,
        data,
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    });
  }

  async put(url, data) {
    return new Promise(async function (resolve, reject) {
      await axios({
        method: "put",
        url: url,
        data,
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  async generateImage(url) {
    return new Promise(async function (resolve, reject) {
      await axios({
        method: "post",
        url,
        headers: {
          Accept: "*/*",
          "Content-Type": "image/jpeg",
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    });
  }

  async genrateMcq(url, data) {
    return new Promise(async function (resolve, reject) {
      await axios({
        method: "post",
        url,
        data,
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    });
  }

  
}

export default new CommonService();
