import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import ButtonLoader from "./ButtonLoader";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import { FaEye } from "react-icons/fa";

const PromptSection = styled.section`
  height: 100vh;
  width: 100%;
  // background-color: rgba(8, 19, 54, 100%);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;

  > .promt_mess_sec {
    background-color: #f2f2f2;
    padding: 50px 30px;
    box-sizing: border-box;
    border-radius: 16px;
    position: relative;
    width: 500px;

    @media screen and (max-width: 575px) {
      width: 100%;
      padding: 25px 15px;
    }
    label {
      color: #333;
    }
    .selectMulti__control {
      border: #333;
    }

    > .prompt-title {
      text-align: center;
      color: #081336;
      font-size: 20px;
      margin-bottom: 30px;
    }
    > .myInput {
      > label {
        color: #333;
      }
      input {
        border: 1px solid #081336;
        font-size: 18px;
      }
    }

    > .btn_grps {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      > button {
        width: 100px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #081336;
        cursor: pointer;
        font-size: 18px;

        &:first-child {
          color: #f2f2f2;
          background-color: red;
        }
        &:last-child {
          color: #f2f2f2;
          background-color: #081336;
        }

        &:hover {
          background-color: transparent;
          color: #081336;
        }
      }
    }
  }
`;

const PopupPrompt = ({
  confirmBtnTxt,
  cancelBtnTxt,
  confirmbtnWidth,
  className,
  confirmOnclick,
  cancelOnclick,
  title,
  lightTitle,
  loading,
  newBookPlaceholder,
  newBookLabel,
  newbookValue,
  onNewBookChange,

  newChapterPlaceholder,
  newChapterLabel,
  newChapterValue,
  onNewChapterChange,
  bookOption,

  onNewChapterNameChange,
  newChapterNamePlaceholder,
  newChapterNameLabel,
  newChapterNameValue,

  onChapterIdChange,
  chapterIdPlaceholder,
  chapterIdLabel,
  chapterIdValue,
  chapterOption,

  chapterData,
  divRef,
  
}) => {
  const handleViewChapter = (id) => {
    const url = `/chapter-details/${id}`;
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      {title && (
        <PromptSection>
          <div
            className={`promt_mess_sec ${className ? className : ""}`}
            ref={divRef}
          >
            {!lightTitle ? (
              <h2 className="prompt-title">{title}</h2>
            ) : (
              <p className="prompt-title">{title}</p>
            )}
            {newBookPlaceholder && (
              <CustomInput
                value={newbookValue}
                name={newbookValue}
                placeholder={newBookPlaceholder}
                label={newBookLabel}
                onChange={onNewBookChange}
                impStar="*"
              />
            )}

            {newChapterPlaceholder && (
              <CustomSelect
                name={newChapterValue}
                value={newChapterValue}
                placeholder={newChapterPlaceholder}
                label={newChapterLabel}
                optionArray={bookOption}
                borderColor="#081336"
                onChange={onNewChapterChange}
              />
            )}

            {chapterIdPlaceholder && (
              <CustomSelect
                name={chapterIdValue}
                value={chapterIdValue}
                placeholder={chapterIdPlaceholder}
                label={chapterIdLabel}
                optionArray={chapterOption}
                borderColor="#081336"
                onChange={onChapterIdChange}
              />
            )}

            {newChapterNamePlaceholder && (
              <CustomInput
                value={newChapterNameValue}
                name={newChapterNameValue}
                placeholder={newChapterNamePlaceholder}
                label={newChapterNameLabel}
                onChange={onNewChapterNameChange}
                impStar="*"
              />
            )}

            {chapterData?.length > 0 && (
              <div className="chapter_list">
                {chapterData?.map((ele, idx) => (
                  <div className="chapter_box" key={ele?._id}>
                    <strong>{idx + 1}.</strong>
                    <p>
                      <span>{ele?.name}</span>
                      <span onClick={() => handleViewChapter(ele?._id)}>
                        <FaEye />
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}

            <div className="btn_grps">
              {confirmOnclick && (
                <button
                  onClick={confirmOnclick}
                  style={{ width: confirmbtnWidth ? confirmbtnWidth : "100px" }}
                >
                  {loading ? <ButtonLoader /> : confirmBtnTxt}
                </button>
              )}
              {cancelOnclick && (
                <button onClick={cancelOnclick}>{cancelBtnTxt}</button>
              )}
            </div>
          </div>
        </PromptSection>
      )}
    </Fragment>
  );
};

export default PopupPrompt;
