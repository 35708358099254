import React, { Fragment } from "react";
import "../styles/privacyPolicy.scss";
import GptLayout from "../component/ssbGpt/gptLayout";
import CustomHelmet from "../component/reusable/Helmet";
import { Link, useNavigate } from "react-router-dom";
import { logo_main } from "../assets/images";
import { AiOutlineArrowLeft } from "react-icons/ai";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="NDA-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <section className="privacy_policy_main txt_color ">
          <div className="w_bg">
            <div className="top_bar">
              <div className="go_back">
                <Link onClick={() => navigate(-1)}>
                  <AiOutlineArrowLeft />
                </Link>
                <p>PRIVACY POLICY</p>
              </div>
            </div>

            <section className="policy_layout_main">
              <div className="profile_content">
                <div className="privacy_content">
                  <div className="title">
                    <img src={logo_main} alt="logo" />
                    <p>Last updated: July 15, 2024</p>
                  </div>
                  <div className="privacy_text">
                    <h2 className="heading">1. Introduction</h2>
                    <p>
                      This Privacy Policy explains how we collect, use,
                      disclose, and safeguard your information when you use SSB
                      GPT, an AI chatbot designed to provide information about
                      the Service Selection Board (SSB) and defense-related
                      queries. We are committed to protecting your privacy and
                      ensuring the security of your personal information.
                    </p>

                    <h2 className="heading">2. Information We Collect</h2>

                    <h4 className="sub_heading">Personal Information:</h4>
                    <p>- Name (if provided)</p>
                    <p>
                      - Email address (if provided for account creation or
                      notifications)
                    </p>
                    <p>- Age or date of birth (to verify eligibility)</p>
                    <p>- Gender (if voluntarily provided)</p>

                    <h4 className="sub_heading">Usage Data:</h4>
                    <p>- Chat logs and conversation history</p>
                    <p>- Time and date of access</p>
                    <p>
                      - Device information (type, operating system, browser)
                    </p>
                    <p>- IP address</p>
                    <p>- Geographic location (country and city level only)</p>
                    <h4 className="sub_heading">
                      SSB and Defense-Related Information:
                    </h4>
                    <p>- Questions asked about SSB processes</p>
                    <p>- Queries related to defense services</p>
                    <p>- Information shared about personal SSB preparation</p>

                    <h2 className="heading">3. How We Use Your Information</h2>
                    <p>
                      We use the collected information for the following
                      purposes:
                    </p>
                    <p>a) To provide and maintain SSB GPT services</p>
                    <p>b) To improve and personalize user experience</p>
                    <p>
                      c) To analyze usage patterns and enhance our chatbot's
                      performance
                    </p>
                    <p>d)To respond to user inquiries and provide support</p>
                    <p>e) To comply with legal obligations</p>
                    <h2 className="heading">4. Data Retention</h2>
                    <p>
                      We retain your personal information and chat logs for 3
                      Months after your last interaction with SSB GPT. After
                      this period, your data is anonymized or deleted, except
                      where we have a legal obligation to retain it longer.
                    </p>

                    <h2 className="heading">5. Data Security</h2>
                    <p>
                      We implement appropriate technical and organizational
                      measures to protect your personal information from
                      unauthorized access, disclosure, alteration, or
                      destruction. However, no method of transmission over the
                      Internet or electronic storage is 100% secure, and we
                      cannot guarantee absolute security.
                    </p>

                    <h2 className="heading">6. Sharing of Information</h2>
                    <p>
                      We do not sell, trade, or rent your personal information
                      to third parties. We may share your information in the
                      following circumstances:
                    </p>
                    <p>
                      1. With service providers who assist in operating SSB GPT
                    </p>
                    <p>
                      2. To comply with legal obligations or respond to lawful
                      requests
                    </p>
                    <p>
                      3. To protect our rights, privacy, safety, or property
                    </p>
                    <p>
                      4. In connection with a merger, acquisition, or sale of
                      assets (with notice provided)
                    </p>
                    <h2 className="heading">7. User Rights</h2>
                    <p>You have the right to:</p>
                    <p>1. Access your personal information</p>
                    <p>2. Correct inaccurate or incomplete information</p>
                    <p>
                      3. Request deletion of your data (subject to legal
                      retention requirements)
                    </p>
                    <p>4. Object to or restrict the processing of your data</p>
                    <p>
                      5. Request a copy of your data in a structured,
                      machine-readable format
                    </p>
                    <p>
                      To exercise these rights, please contact us using the
                      information provided in Section 12.
                    </p>

                    <h2 className="heading">
                      8. Cookies and Similar Technologies
                    </h2>
                    <p>
                      SSB GPT may use cookies or similar tracking technologies
                      to enhance user experience and collect usage data. You can
                      control cookie settings through your browser preferences.
                    </p>

                    <h2 className="heading">9. Third-Party Links</h2>
                    <p>
                      SSB GPT may contain links to third-party websites or
                      services. We are not responsible for the privacy practices
                      or content of these third parties. We encourage you to
                      review their privacy policies before providing any
                      personal information.
                    </p>

                    <h2 className="heading">10. Children's Privacy</h2>
                    <p>
                      SSB GPT is not intended for use by individuals under the
                      age of 14. We do not knowingly collect personal
                      information from children. If we become aware that we have
                      collected personal information from a child without
                      parental consent, we will take steps to remove that
                      information.
                    </p>

                    <h2 className="heading">
                      11. Changes to This Privacy Policy
                    </h2>
                    <p>
                      We may update this Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page and updating the "Effective Date" at
                      the top. You are advised to review this Privacy Policy
                      periodically for any changes.
                    </p>

                    <h2 className="heading">12. Contact Us</h2>
                    <p>
                      If you have any questions about this Privacy Policy or our
                      data practices, please contact us at:
                      <Link to="/">admin@ssbgpt.in</Link>
                      Address :{" "}
                      <Link to="/">
                        Alpha Regiment Campus, Haily Mandi Road, Farukhnagar,
                        Gurgaon - 122506
                      </Link>
                    </p>
                    <h2 className="heading">
                      13. Specific Provisions for SSB and Defense-Related
                      Information
                    </h2>
                    <p>
                      <strong>1. Sensitive Information:</strong> We recognize
                      that queries related to SSB and defense services may
                      involve sensitive information. We do not collect or store
                      any classified or restricted defense information.
                    </p>
                    <p>
                      <strong>2. Anonymization:</strong> All SSB-related
                      queries and responses are anonymized before being used for
                      analysis or improvement of our services.
                    </p>
                    <p>
                      <strong>3. No Official Records:</strong> Information
                      provided to or by SSB GPT is not considered an official
                      record for any SSB or defense-related processes.
                    </p>

                    <p>
                      <strong>4 Data Localization:</strong> All data collected by
                      SSB GPT is stored on servers located within India, in
                      compliance with local data protection laws.
                    </p>
                    <h2 className="heading">14. Consent</h2>
                    <p>
                      By using SSB GPT, you consent to the collection and use of
                      information as outlined in this Privacy Policy. If you do
                      not agree with this policy, please do not use our service.
                    </p>

                    <h2 className="heading">15. Data Transfer</h2>
                    <p>
                      In the event that data needs to be transferred outside the
                      country of origin for processing or storage, we ensure
                      that appropriate safeguards are in place to protect your
                      information and comply with applicable data protection
                      laws.
                    </p>

                    <h2 className="heading">16. Breach Notification</h2>
                    <p>
                      In the event of a data breach that may compromise your
                      personal information, we will notify you and the relevant
                      authorities as required by applicable laws.
                    </p>

                    <h2 className="heading">17. Accountability</h2>
                    <p>
                      We are committed to adhering to the principles outlined in
                      this Privacy Policy and are accountable for our data
                      protection practices. We regularly review and update our
                      privacy procedures to ensure ongoing compliance and
                      protection of your information.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <Link to="/" className="goHome_btn">
              Home
            </Link>
          </div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default PrivacyPolicy;
