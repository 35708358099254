import React from "react";
import "../../styles/loader.scss";
import { alphaLoader } from "../../assets/images";

const Loader = () => {
  return (
    <>
      <section className="loader_img">
        <div id="particles-foreground"></div>
        <div id="particles-background"></div>

        <div className="vertical-centered-box">
          <div className="content">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div>
            <img src={alphaLoader} alt="loader" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Loader;
