import React, { Fragment, useEffect, useState } from "react";
import "../styles/forgotPassword.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { forgotPassword } from "../assets/images";
import Loader from "../component/reusable/Loader";
import CustomHelmet from "../component/reusable/Helmet";
import GptLayout from "../component/ssbGpt/gptLayout";
import CustomInput from "../component/reusable/CustomInput";
import {
  clearErrors,
  clearUpdateState,
  requestForgotPassword,
} from "../redux/actions/userAction";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  // FORGOT PASSWORD REQUEST START
  // FORGOT PASSWORD REQUEST START
  const { loading, data, error } = useSelector((state) => state.forgotPassword);

  const handleForgotPassword = () => {
    if (!email) {
      toast.warning("Please provide your registered email id!");
    } else {
      dispatch(requestForgotPassword(email));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors);
    }

    if (data?.success) {
      toast.success(data?.message);
      dispatch(clearUpdateState());
    }
  }, [data?.message, data?.success, dispatch, error]);

  // FORGOT PASSWORD REQUEST ENDS
  // FORGOT PASSWORD REQUEST ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <GptLayout>
            <CustomHelmet
              keywords="Forgot Password Alpha Regiment"
              description="Welcome again, Alpha Soldier, let's complete today's stuff so that tomorrow we will be free to serve the motherland."
              author="Team Alpha Regiment"
              title="Forgot Password || Alpha Regiment"
            />
            <section className="forgotPassword_main">
              <div className="container">
                <div className="forgotPassword_form">
                  <div className="login_form_inputs">
                    <div className="mobileTitle">
                      <h1>Forgot Password</h1>
                    </div>
                    <div className="login_logo">
                      <img src={forgotPassword} alt="forgotPassword" />
                    </div>
                    <div className="form">
                      <div className="title">
                        <h1>Forgot Password</h1>
                      </div>
                      <CustomInput
                        label="Your Registered Mail Id"
                        placeholder="Registered Mail Id"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        impStar="*"
                      />

                      <button
                        className="login_submit_btn"
                        onClick={handleForgotPassword}
                      >
                        Forgot Password
                      </button>
                      <button
                        className="go_home_btn"
                        onClick={() => navigate("/")}
                      >
                        Home
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </GptLayout>
        </>
      )}
    </Fragment>
  );
};

export default ForgotPassword;
