import React, { Fragment } from "react";
import "../../styles/whatCanDo.scss";
import {
  AiPowered,
  CentralizedStudyHub,
  PreferredFromat,
  StudyResource,
  UnlimitedNDAMockTest,
  VideoNotes,
} from "../../assets/images";

const WhatCanDo = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="why_choose_us_ssb_gpt">
          <h1 className="title">What Can You Do with NDA-GPT?</h1>
          <div className="card_list">
            <div className="card">
              <img src={AiPowered} alt="explore campus" />
              <h2>AI-Powered Analysis</h2>
              <p>
                Take a simple psychometrics MCQ test to evaluate your Officer
                Like Qualities (OLQs), identify your weaknesses, and start
                working on them effectively.
              </p>
            </div>
            <div className="card">
              <img src={StudyResource} alt="connect improve" />
              <h2>All-in-One Study Resource for NDA Exam</h2>
              <p>
                Enhance your TAT & PPDT prep with AI-generated images and story
                checks. Start preparing now and boost your success with AI's
                assistance!
              </p>
            </div>
            <div className="card">
              <img src={CentralizedStudyHub} alt="connect practice" />
              <h2>Centralized Study Hub</h2>
              <p>
                Save and organize screenshots, images, text, and videos. Evolve
                your note-taking with all-in-one digital access. Stay organized
                and access your notes anytime, anywhere.
              </p>
            </div>
            <div className="card">
              <img src={PreferredFromat} alt="connect practice" />
              <h2>Choose Your Preferred Format</h2>
              <p>
                Easily find videos related to your questions, add notes directly
                within the video for future reference, and save everything in
                your notes for convenient access.
              </p>
            </div>
            <div className="card">
              <img src={UnlimitedNDAMockTest} alt="connect practice" />
              <h2>Unlimited NDA Mock Tests</h2>
              <p>
                Enhance your WAT & SRT preparation with AI-generated scenarios
                and timed word exercises. Receive instant AI feedback on your
                answers. Begin your preparation today!
              </p>
            </div>
            <div className="card">
              <img src={VideoNotes} alt="connect practice" />
              <h2>Video Notes for Easy Review</h2>
              <p>
                Practice GTO tasks using 3D simulation to excel in PGT, HGT, and
                FGT by analyzing structures and enhancing performance for the
                Service Selection Board
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhatCanDo;
