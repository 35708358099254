import { applyMiddleware, combineReducers, createStore } from "redux";
import {thunk} from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  forgotPasswordReducer,
  userLoginReducer,
  userProfileReducer,
  userRegisterReducer,
} from "./reducers/userReducer";

// get user from localStorage
const userFromLocalStorage = localStorage.getItem("ssb-token")
  ? JSON.parse(localStorage.getItem("ssb-token"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister:userRegisterReducer,
  profile: userProfileReducer,
  forgotPassword:forgotPasswordReducer,
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
