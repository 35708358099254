import { userEndPoints } from "../config";
import CommonService from "./CommonService";

class bookService {
  async createUserNewBook(data) {
    return CommonService.post(userEndPoints.createNewBook, {
      name: data,
    });
  }

  async myAllBookList() {
    return CommonService.get(userEndPoints.myBookList);
  }

  async addNewChapter(id, chapterName) {
    return CommonService.post(userEndPoints.createChapter + `/${id}`, {
      name: chapterName,
    });
  }

  async addNewChapterData(id, answerType, data) {
    return CommonService.post(userEndPoints.createChapterData + `${id}`, {
      question: data?.question,
      answer: data?.answer.replace(/Alpha Bot:\s*/g, ""),
      type: answerType,
      text: data?.answer.replace(/Alpha Bot:\s*/g, ""),
      image: data?.image,
    });
  }

  async updateChapterData(data,answerText, extraData) {
    return CommonService.put(userEndPoints.updateChapterData + `${data?._id}`, {
      question: data?.question,
      answer: answerText.replace(/Alpha Bot:\s*/g, ""),
      type: data?.type,
      text: extraData,
      image: data?.image,
    });
  }

  async myAllChapterbyBookid(id) {
    return CommonService.get(userEndPoints.getChapterByBookId + `/${id}`);
  }

  async chapterDetailByChapterId(id) {
    return CommonService.get(userEndPoints.chapterDetail + `/${id}`);
  }

  async getVideoSearch(search) {
    return CommonService.get(userEndPoints.searchVideo + search);
  }
}
export default new bookService();
