import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/gptSearch.scss";
import { CiSearch } from "react-icons/ci";
import { AiFillAudio, AiFillDislike, AiFillLike } from "react-icons/ai";
import axios from "axios";
import { LuBookmark, LuLoader } from "react-icons/lu";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiVoiceprintFill,
} from "react-icons/ri";
import GptSidebar from "./gptSidebar";
import { TiArrowUp } from "react-icons/ti";
import { MdAdd, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useTour } from "@reactour/tour";
import {
  courses,
  currentAffair,
  imgAdd,
  lamp1,
  lamp2,
  musicPlayer,
  lamp3,
  linkSearch,
  loaderGif,
  logo_main,
  searchBook,
  ssbBook,
  why1,
} from "../../assets/images";
import userService from "../../services/userService";
import PopupPrompt from "../reusable/PopupPrompt";
import bookService from "../../services/bookService";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { BsPlayCircle } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userEndPoints } from "../../config";
import Loader from "../reusable/Loader";
import { useAuth } from "../../context/userContext";
import moment from "moment";
import CustomHelmet from "../reusable/Helmet";
import WhatCanDo from "./WhatCanDo";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const GptSearch = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // HANDLE TAT IMAGE TEST STATE  STARTS

  // HANDLE TAT IMAGE TEST STATE  STARTS
  const divRef = useRef(null);

  // HANDLE TAT IMAGE TEST STATE  ENDS

  // HANDLE TAT IMAGE TEST STATE  ENDS

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const localToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : {};

    const data = {
      success: true,
      token: token,
    };
    if (!localToken) {
      localStorage.setItem("ssb-token", data);
    }
  }, [location.search]);

  const [searchValue, setSearchValue] = useState("");
  const [placeHolderInput, setPlaceholderInput] = useState(
    "Search anything related to ssb"
  );
  const [activeTab, setActiveTab] = useState("question");
  const [activeinput, setActiveInput] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localHistory, setlocalHistory] = useState([]);
  const [mediaOpen, setMediaOpen] = useState(false);

  const [pdfFile, setPdfFile] = useState("");

  // ALL BOOK SAVE STATE STARTS

  const [addBook, setAddBook] = useState(false);
  const [bookName, setBookName] = useState("");
  const [newBookLoading, setNewBookLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [addChapter, setAddChapter] = useState(false);
  const [chapterBook, setChapterBook] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [saveIndex, setSaveIndex] = useState(0);
  const [chapterModal, setChapterModal] = useState(false);
  const [bookChapterList, setBookChapterList] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [showBook, setShowBook] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [chapterLoading, setChapterLoading] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);

  // GET CHAPTER LIST BY BOOK ID

  useEffect(() => {
    if (chapterBook !== "") {
      getChapterListBybookId(chapterBook);
    }
  }, [chapterBook]);

  useEffect(() => {
    const existingLocalHistory = localStorage.getItem("userHistory")
      ? JSON.parse(localStorage.getItem("userHistory"))
      : [];
    setlocalHistory(existingLocalHistory);
  }, []);

  const todatDate = new Date();
  const currentDate = moment(todatDate).format("YYYY-MM-DD");

  const handleVoice = () => {
    setIsListening(true);
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.maxResults = 10;
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchValue(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
  };

  //  POST DATA TO API START
  const handleAskQuestion = () => {
    setPlaceholderInput("Ask anything related to ssb");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("question");
  };

  const handleSearchResult = async (query) => {
    setIsLoading(true);
    setSearchValue("");
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await userService.userChat(searchValue);
      if (result) {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );
        let updatedList = [...localHistory];
        const existingEntry = updatedList.find(
          (item) => item.dayData === currentDate
        );

        if (existingEntry) {
          existingEntry.searchData.push({
            question: searchValue,
            answer: result?.data?.response,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: result?.data?.response,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  //  POST DATA TO API ENDS

  // OPEN MEDIA SELECT BAR STARTS

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setMediaOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMediaOpen = () => {
    setMediaOpen(!mediaOpen);
  };

  // OPEN MEDIA SELECT BAR ENDS

  // IMAGE GENRATE ENDS

  // Link TEST GENRATE START
  const handleLinkSearch = () => {
    setPlaceholderInput("Write the link to summerise the data");
    setActiveInput(true);
    setActiveTab("link");
    setMediaOpen(false);
  };

  const handleLinkGenrate = async () => {
    setIsLoading(true);
    setSearchValue("");

    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.linkSummarize, {
        input_text: searchValue,
      });

      if (result?.data?.response !== "No input provided") {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );

        const currentDate = moment().format("YYYY-MM-DD");
        let updatedList = [...questionList];

        if (
          updatedList.length > 0 &&
          updatedList[updatedList.length - 1].dayData === currentDate
        ) {
          updatedList[updatedList.length - 1].searchData.push({
            question: searchValue,
            answer: result?.data?.response,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: result?.data?.response,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      } else {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: "Sorry i have no provided data for that",
                }
              : entry
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMediaOpen(false);
    }
  };
  //Link TEST GENRATE ENDS

  // HANDLE PDF UPLOAD START
  const handlePdfSearch = () => {
    setActiveInput(true);
    setActiveTab("pdf");
    setMediaOpen(false);
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
    }
  };

  const handlePdfUpload = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", pdfFile);

      const result = await axios.post(userEndPoints.pdfUpload, formData);

      if (result) {
        setOutputData([
          ...outputData,
          { answer: result?.data?.response, question: searchValue },
        ]);
        setIsLoading(false);
        setSearchValue("");
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  const handlePdfSearchQuestion = async () => {
    setIsLoading(true);
    setSearchValue("");
    setMediaOpen(false);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.askQuestion, {
        input_text: searchValue,
      });

      if (result) {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );
        const currentDate = moment().format("YYYY-MM-DD");
        let updatedList = [...questionList];

        if (
          updatedList.length > 0 &&
          updatedList[updatedList.length - 1].dayData === currentDate
        ) {
          updatedList[updatedList.length - 1].searchData.push({
            question: searchValue,
            answer: result?.data?.response,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: result?.data?.response,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  // HANDLE PDF UPLOAD ENDS

  // HANDLE VIDEO SEARCH STARTS
  const handleVideoSearch = () => {
    setPlaceholderInput("Search Video");
    setActiveInput(true);
    setActiveTab("video");
    setMediaOpen(false);
  };

  // const getYouTubeVideoId = (url) => {
  //   const regex = /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
  //   const match = url.match(regex);
  //   return match ? match[1] : null;
  // };

  const fetchVideo = async () => {
    setIsLoading(true);
    setSearchValue("");
    setMediaOpen(false);
    const newEntry = {
      question: searchValue,
      url: null,
      answer: "Loading...",
      videoTitle: "",
    };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await bookService.getVideoSearch(searchValue);
      if (result) {
        // const videoId = getYouTubeVideoId(result?.data?.video?.url);
        const videoId = result?.data?.video?.url;

        setIsLoading(false);
        setPlaceholderInput("Search video");
        setActiveTab("video");
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  url: videoId,
                  answer: `Here is your video for your ${searchValue} search`,
                  videoTitle: result?.data?.title,
                }
              : entry
          )
        );

        const currentDate = moment().format("YYYY-MM-DD");
        let updatedList = [...questionList];
        if (
          updatedList.length > 0 &&
          updatedList[updatedList.length - 1].dayData === currentDate
        ) {
          updatedList[updatedList.length - 1].searchData.push({
            question: searchValue,
            answer: `Here is your video for your ${searchValue} search`,

            url: videoId,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: `Here is your video for your ${searchValue} search`,
                url: videoId,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  const handleVideoView = (url) => {
    // const videoId = getYouTubeVideoId(url);
    if (url) {
      navigate(`/video?streamId=${url}`);
    }
  };

  // HANDLE VIDEO SEARCH ENDS

  // INTERVIEW ATTEND FUNCTION API'S CALL STARTS
  // INTERVIEW ATTEND FUNCTION API'S CALL STARTS

  const handleNavigateCourses = () => {
    window.open("https://testing.alpharegiment.in/ssb-courses");
  };

  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS
  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS

  // BOOK STATE HANDLING STARTS
  const handleAddChapter = () => {
    setAddChapter(true);
    setChapterModal(false);
  };

  const [saveUnit, setSaveUnit] = useState(false);

  const addNewUnit = async () => {
    setNewBookLoading(true);
    const data = outputData?.find((_, id) => saveIndex === id);
    const type = activeTab === "TAT" ? "TAT" : "text";
    try {
      const res = await bookService.addNewChapterData(chapterId, type, data);
      if (res?.data?.success) {
        setSaveUnit(false);
        setChapterBook("");
        setChapterId("");
        setNewBookLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setSaveUnit(false);
        setChapterBook("");
        setChapterId("");
        setNewBookLoading(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setSaveUnit(false);
    }
  };

  const handleAddBookModal = () => {
    if (auth?.user?._id) {
      setAddBook(true);
    } else {
      toast.error("Login before adding new book");
    }

    setShowBook(false);
  };

  const fetchBookList = async () => {
    try {
      setAuthLoading(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setAuthLoading(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);

  const handleAddnewBook = async () => {
    setNewBookLoading(true);
    try {
      const res = await bookService.createUserNewBook(bookName);
      if (res?.data) {
        toast.success("Book Add Successfully");
        setNewBookLoading(false);
        fetchBookList();
        setBookName("");
        setAddBook(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setBookName("");
      setAddBook(false);
    }
  };

  // ADD NEW BOOK ENDS

  let optionBookList = [];

  bookList?.forEach((ele) => {
    optionBookList.push({
      option: ele?.name,
      value: ele?._id,
    });
  });

  let chapterListFromBookId = [];
  bookChapterList?.chapters?.forEach((ele) => {
    chapterListFromBookId.push({
      option: ele?.name,
      value: ele?._id,
    });
  });

  // ADD NEW CHAPTER STARTS

  const handleChapterSave = (idx) => {
    setSaveIndex(idx);
    setSaveUnit(true);
  };

  const handleAddNewChapter = async () => {
    setNewBookLoading(true);
    try {
      const res = await bookService.addNewChapter(chapterBook, chapterName);
      if (res?.data?.success) {
        setAddChapter(false);
        setChapterBook("");
        setNewBookLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setAddChapter(false);
        setChapterBook("");
        setNewBookLoading(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setAddChapter(false);
    }
  };

  const openBook = (id) => {
    setChapterModal(true);
    getChapterListBybookId(id);
  };

  const getChapterListBybookId = async (id) => {
    setChapterLoading({
      loading: true,
      index: id,
    });
    try {
      const res = await bookService.myAllChapterbyBookid(id);
      if (res?.data?.success) {
        setShowBook(false);
        setBookChapterList(res?.data);
        setChapterLoading(false);
      }
    } catch (error) {
      setShowBook(false);
      setChapterLoading(false);
    }
  };

  // TAT TEST FUNCTIONALTY

  // test start timer

  // STEPS FOR GIVING OVERVIEW
  const { currentStep, isOpen } = useTour();

  useEffect(() => {
    if (currentStep === 0 && isOpen) {
      setMediaOpen(true);
    }
  }, [currentStep, isOpen]);

  // SCROLL HER PAGE AFTER EVERY SEARCH STARTS
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && outputData) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  // SCROLL HER PAGE AFTER EVERY SEARCH ENDS

  // CHECK USER TODAY SEARCH HISTORY STARTS
  const savedList = localStorage.getItem("userHistory");

  const [srchToday, setSrchToday] = useState(true);

  useEffect(() => {
    if (savedList) {
      const data = JSON.parse(savedList);
      if (data === null) {
        setSrchToday(true);
      } else {
        const today = new Date().toISOString().split("T")[0];
        const checkSearchToday = data.some((item) => item.dayData === today);
        const searchHistory = data.find(
          (item) => item.dayData === today
        )?.searchData;
        if (searchHistory) {
          setOutputData(searchHistory);
        }
        setSrchToday(checkSearchToday);
      }
      setQuestionList(data);
    }
  }, [savedList]);

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  // CHECK USER TODAY SEARCH HISTORY ENDS

 

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      handleSearch();
    }
  };

  // HANDLE FUNCTION FOR ANALYSING USER INPUT
  const keywords = {
    srt: ["srt", "situation", "situations", "Give SRT", "Practice srt"],
    link: ["http", "https", "www"],
    ppdt: ["PP&DT", "PP & DT", "ppdt", "PPDT"],
    tat: ["tat", "TAT"],
    wat: ["word", "wat", "WAT"],
    interview: ["interview"],
    pdfSearch: ["pdf", "pdfSearch"],
    olq: ["olq", "OLQ", "olq analysis"],
    oir: ["Give OIR", "OIr test", "oir"],
    spoken: ["Spoken English", "Spoken", "english"],
    video: ["video", "learn by video"],
    courses: [
      "Coaching",
      "SSB Institute",
      "Psychology dossier checked by ex-psychologist ",
      "Mock Psychology",
      "Mock Interview",
      "Mock SSB",
    ],
  };

  const [message, setMessage] = useState("");
  const [suggestionTab, setSuggestionTab] = useState("ask");

  const handleInputChange = (e) => {
    const valueData = e.target.value;
    setSearchValue(valueData);
    analyzeInput(valueData.trim());
  };

  const analyzeInput = (input) => {
    const lowerCaseInput = String(input).toLowerCase();
    let matchedKeyword = null;
    Object.keys(keywords).forEach((keyword) => {
      if (
        keywords[keyword].some((pattern) =>
          lowerCaseInput.includes(pattern.toLowerCase())
        )
      ) {
        matchedKeyword = keyword;
      }
    });
    if (matchedKeyword === "srt") {
      setMessage(`Would you like to get SRT?`);
      setSuggestionTab("srt");
    }
    if (matchedKeyword === "wat") {
      setMessage(`Would you like to get WAT?`);
    }
    if (matchedKeyword === "ppdt") {
      setMessage(`Would you like to get  PPDT / PP&DT?`);
      setSuggestionTab("PPDT");
    }
    if (matchedKeyword === "tat") {
      setMessage(`Would you like to get TAT ?`);
      setSuggestionTab("TAT");
    }
    if (matchedKeyword === "oir") {
      setMessage(`Would you like to get OIR ?`);
      setSuggestionTab("oir");
    }
    if (matchedKeyword === "pdfSearch") {
      setMessage(`Would you like a summary, MCQs, or specific information from the PDF?
`);
      setSuggestionTab("pdf");
    }
    if (matchedKeyword === "olq") {
      setMessage(
        `Do you mean you want to get your OLQ analysis through a simple psychometric test?`
      );
    }
    if (matchedKeyword === "link") {
      setMessage(
        `Would you like a summary, MCQs, or specific information from this link?`
      );
      setSuggestionTab("link");
    }

    if (matchedKeyword === "interview") {
      setMessage(`Want to give an interview`);
      setSuggestionTab("interview");
    }
    if (matchedKeyword === "courses") {
      setMessage(`Want to browse ssb courses`);
      setSuggestionTab("courses");
    }

    if (matchedKeyword === "video") {
      setMessage(`Would you like your questions answered in a video?
`);
      setSuggestionTab("video");
    }

    if (matchedKeyword === "spoken") {
      setMessage(`Do you mean you want to practice spoken English with AI?
`);
    }

    return matchedKeyword;
  };

  // HANDLE SEARCH VIW POPUP MESSAGE

  const handleSearchPop = () => {
    setMessage("");
    if (suggestionTab === "question") {
      handleSearchResult();
    }

    if (suggestionTab === "link") {
      handleLinkGenrate();
    }
    if (suggestionTab === "pdf") {
      handlePdfUpload();
    }

    if (suggestionTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }
    if (suggestionTab === "video") {
      fetchVideo();
    }
    if (suggestionTab === "courses") {
      handleNavigateCourses();
    }
  };

  const handleSearch = () => {
    setMessage("");
    if (activeTab === "question") {
      handleSearchResult();
    }

    if (activeTab === "link") {
      handleLinkGenrate();
    }

    if (activeTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }
    if (activeTab === "video") {
      fetchVideo();
    }
  };

  // SPOKEN ENGLISH ENDS

  // CLOSE MEDIA WRAPPER WEN CLICK OUTSIDE
  // const mediaRef = useRef(null);
  // const handleOutsideClick = (event) => {
  //   if (!mediaRef.current.contains(event.target)) {
  //     setMediaOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('click', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, []);

  return authLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <section className="gpt_layout_wrapper">
          <GptSidebar setOutputData={setOutputData} />

          <section className="gpt_Search_box">
            <div className="input_box">
              <div className={`srch_output custonScroll`} ref={scrollRef}>
                {!srchToday && (
                  <div className="wlcm_msg">
                    <div className="title">
                      <h2>NDA-GPT</h2>
                    </div>
                    <div className="overview_box">
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp1} alt="lamp1" />
                          <p>Examples</p>
                        </div>
                        <ul>
                          <li>
                            <p>
                              Provide me with study material on Geography for
                              the NDA exam.
                            </p>
                          </li>
                          <li>
                            <p>
                              Give me with a short test on this Geography topic.
                            </p>
                          </li>
                          <li>
                            <p>Today's current affairs.</p>
                          </li>
                        </ul>
                      </div>
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp2} alt="lamp2" />
                          <p>Capabilities</p>
                        </div>
                        <ul>
                          <li>
                            <p>
                              NDA-GPT offers study material for all NDA
                              subjects.
                            </p>
                          </li>
                          <li>
                            <p>NDA-GPT can conduct unlimited mock tests.</p>
                          </li>
                          <li>
                            <p>
                              NDA-GPT provides content in both text and video
                              formats.
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp3} alt="lamp3" />
                          <p>Quality</p>
                        </div>
                        <ul>
                          <li>
                            <p>Trained by NDA Teachers.</p>
                          </li>
                          <li>
                            <p>Keep all your notes organized in one place.</p>
                          </li>
                          <li>
                            <p>
                              NDA-GPT tracks your progress & suggests optimal
                              strategies.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* SEARCH HISORTY AND ASK QUESTION BOX  */}
                {outputData?.length > 0 &&
                  outputData?.map((ele, idx) => (
                    <>
                      {ele?.question && <h5>{ele?.question}</h5>}
                      {ele?.srtList?.length > 0 && (
                        <h5>
                          {ele?.srtList?.map((itm, id) => (
                            <span key={id}>
                              {id + 1}. {itm}
                            </span>
                          ))}
                        </h5>
                      )}
                      {(ele?.answer || ele?.spokenType?.length > 0) && (
                        <div className="answer_box">
                          <div className="output_box">
                            
                            <div className="video_data">
                              <div className="video_content" key={idx}>
                                {ele?.title !== "" && <p>{ele?.title}</p>}
                                {ele?.url && ele?.url !== null && (
                                  <div className="thumbnail-container">
                                    <iframe
                                      className="thumbnail"
                                      src={ele?.url}
                                      title={ele?.url}
                                      frameBorder="0"
                                      allowFullScreen
                                    />
                                    <div
                                      className="overlay"
                                      onClick={() => handleVideoView(ele?.url)}
                                    >
                                      <BsPlayCircle />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            {ele?.answer !== "" ? (
                              <>
                                {ele?.answer?.Title && (
                                  <p>
                                    <strong>Title :</strong>
                                    <span>{ele?.answer?.Title}</span>
                                  </p>
                                )}
                                {ele?.answer?.Content && (
                                  <p>
                                    <strong>Content :</strong>
                                    <span>{ele?.answer?.Content}</span>
                                  </p>
                                )}
                                {ele?.answer?.Summary && (
                                  <p>
                                    <strong>Summary :</strong>
                                    <span>{ele?.answer?.Summary}</span>
                                  </p>
                                )}
                                {!ele?.answer?.Title &&
                                  !ele?.answer?.Content &&
                                  !ele?.answer?.Summary && (
                                    <p>
                                      <span>
                                        {ele?.answer?.replace(
                                          /Alpha Bot:\s*/g,
                                          ""
                                        )}
                                      </span>
                                    </p>
                                  )}
                              </>
                            ) : (
                              <div className="video_data">
                                <div className="video_content" key={idx}>
                                  {ele?.title !== "" && <p>{ele?.title}</p>}
                                  {ele?.url === "Loading..." ? (
                                    <p>{ele?.url}</p>
                                  ) : (
                                    <div
                                      className="thumbnail"
                                      onClick={() => handleVideoView(ele?.url)}
                                    >
                                      <img src={logo_main} alt="thumbnail" />
                                      <span>
                                        <BsPlayCircle />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="action_btn">
                            <span onClick={() => handleChapterSave(idx)}>
                              <LuBookmark />
                              Save
                            </span>
                            <span>
                              <AiFillDislike />
                            </span>
                            <span>
                              <AiFillLike />
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                {/* SEARCH HISTORY AND ASK QUESTION BOX ENDS */}

                <div className="tour_msg"></div>
                {/* HANLDE TAT LOGICS STARTS  */}

                {activeTab === "TAT" && (
                  <div className="tat_test_msg">
                    <p>
                      Your test is preparing. It will take upto 5-6 min. <br />
                      Do not refresh the page in case you may loss data
                    </p>
                  </div>
                )}

                {activeTab === "PPDT" && (
                  <div className="tat_test_msg">
                    <p>
                      Your test is preparing. It will take upto 40 - 50 sec.{" "}
                      <br />
                      Do not refresh the page in case you may loss data
                    </p>
                  </div>
                )}

                {isLoading && activeTab === "video" && (
                  <div className="search_loader">
                    <img src={loaderGif} alt="loader" />
                  </div>
                )}
              </div>

              <div className="srch_input">
                {message !== "" && (
                  <div className="suggestion_box">
                    <p>{message}</p>
                    <div className="box">
                      <button onClick={() => setMessage("")}>No</button>
                      <button onClick={handleSearchPop}>Yes</button>
                    </div>
                  </div>
                )}
                <div>
                  {activeTab === "pdf" && (
                    <div
                      className="upload_box"
                      style={{ border: activeinput ? "1px solid #fff" : "" }}
                    >
                      <input
                        type="file"
                        accept="*/.pdf"
                        onFocus={() => {
                          setMediaOpen(false);
                        }}
                        onChange={(e) => handleMediaChange(e)}
                        placeholder={placeHolderInput}
                      />
                      <button
                        className="media_btn"
                        onClick={() => handleSearch()}
                      >
                        {isLoading ? <LuLoader /> : <TiArrowUp />}
                      </button>
                    </div>
                  )}

                  {activeTab !== "pdf" && (
                    // && activeTab !== "srt"
                    <input
                      type="text"
                      value={searchValue}
                      style={{ border: activeinput ? "1px solid #fff" : "" }}
                      onChange={(e) => handleInputChange(e)}
                      onFocus={() => {
                        setMediaOpen(false);
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder={placeHolderInput}
                    />
                  )}

                  <div className="media_wrapper">
                    <div
                      className={`media_drop_up ${
                        mediaOpen ? "media_drop_up_on" : ""
                      }`}
                    >
                      <ul>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "question" ? "active" : ""
                            }`}
                            onClick={() => handleAskQuestion()}
                          >
                            <img src={why1} alt="why1" />
                            <p>Ask Question</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que2 ${
                              activeTab === "link" ? "active" : ""
                            }`}
                            onClick={() => handleLinkSearch()}
                          >
                            <img src={linkSearch} alt="linkSearch" />
                            <p>Link Search</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que6 ${
                              activeTab === "pdf" ? "active" : ""
                            }`}
                            onClick={() => handlePdfSearch()}
                          >
                            <img src={searchBook} alt="searchBook" />
                            <p>Book Search</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className="media_select ask_que8"
                            onClick={() => handleVideoSearch()}
                          >
                            <img src={musicPlayer} alt="wat1" />
                            <p>Video Search</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className="media_select ask_que10"
                            onClick={() => handleNavigateCourses()}
                          >
                            <img src={courses} alt="wat1" />
                            <p>Courses</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que13 ${
                              activeTab === "currentAffair" ? "active" : ""
                            }`}
                            // onClick={() => handleSpokenEnglish()}
                          >
                            <img src={currentAffair} alt="currentAffair" />
                            <p>Current Affairs</p>
                          </button>
                        </li>
                        
                      </ul>
                    </div>
                    <button
                      className="media_btn"
                      onClick={() => handleMediaOpen()}
                    >
                      {mediaOpen ? <MdOutlineKeyboardArrowUp /> : <MdAdd />}
                    </button>
                  </div>

                  {searchValue === "" ? (
                    <button className="search" onClick={() => handleVoice()}>
                      {isListening ? <RiVoiceprintFill /> : <AiFillAudio />}
                    </button>
                  ) : (
                    <button className="search" onClick={() => handleSearch()}>
                      {isLoading ? <LuLoader /> : <CiSearch />}
                    </button>
                  )}
                </div>

                <p className="advice_txt">
                  NDA-GPT can make mistakes. We suggest seeking advice from
                  experts. Read our <Link to="/term-of-use">Terms</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </section>

          <div className="gpt_books_wrapper">
            <h2>Books</h2>
            <ul>
              {bookList?.map((ele) => (
                <li key={ele?._id}>
                  <div className="book_tab" onClick={() => openBook(ele?._id)}>
                    <img src={ssbBook} alt="ssb_book" />
                    <p>{ele?.name}</p>
                  </div>
                </li>
              ))}
              <li>
                <div
                  className="book_tab"
                  onClick={() => handleAddBookModal(true)}
                >
                  <img src={imgAdd} alt="imgAdd" />
                  <p>Add New</p>
                </div>
              </li>
            </ul>
          </div>

          <section className="mobile_bar_content">
            <div className="container">
              <div className="mobile_navigation">
                <span onClick={() => setShowHistory(true)}>History</span>
                <span onClick={() => setShowBook(true)}>Book</span>
              </div>
            </div>

            <div className={`book_list ${showBook ? "book_list_show" : ""}`}>
              <div className="gpt_books">
                <div className="title">
                  <h2>Books</h2>
                  <span onClick={() => setShowBook(false)}>
                    <RxCross2 />
                  </span>
                </div>
                <ul>
                  {bookList?.map((ele) => (
                    <li key={ele?._id}>
                      <div
                        className="book_tab"
                        onClick={() => getChapterListBybookId(ele?._id)}
                      >
                        <img src={ssbBook} alt="ssb_book" />
                        <p>
                          {chapterLoading?.loading &&
                          chapterLoading?.index === ele?._id
                            ? "Loading.."
                            : ele?.name}
                        </p>
                      </div>
                    </li>
                  ))}
                  <li>
                    <div
                      className="book_tab"
                      onClick={() => handleAddBookModal()}
                    >
                      <img src={imgAdd} alt="imgAdd" />
                      <p>Add New</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className={`history_list ${
                showHistory ? "history_list_show" : ""
              }`}
            >
              <div className="gpt_books">
                <div className="title">
                  <h2>Search History</h2>
                  <span onClick={() => setShowHistory(false)}>
                    <RxCross2 />
                  </span>
                </div>
                <ul>
                  {questionList?.length > 0 ? (
                    questionList?.map((ele, id) => (
                      <li key={id} onClick={() => setViewHistory(!viewHistory)}>
                        <p>
                          {ele?.dayData}
                          {!viewHistory ? (
                            <RiArrowDropDownLine />
                          ) : (
                            <RiArrowDropUpLine />
                          )}
                        </p>

                        {viewHistory && (
                          <span onClick={() => handleShowHistory(ele?.dayData)}>
                            {ele?.searchData?.map((item, id) => (
                              <p key={id}>
                                {id + 1}. {item?.question}
                              </p>
                            ))}
                          </span>
                        )}
                      </li>
                    ))
                  ) : (
                    <li>
                      <p>No search history found</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </section>
        </section>

        <WhatCanDo />

        {addBook && (
          <PopupPrompt
            divRef={divRef}
            cancelBtnTxt="Cancel"
            cancelOnclick={() => setAddBook(false)}
            confirmBtnTxt="Save Book"
            confirmOnclick={() => handleAddnewBook()}
            title="Add Your New Book"
            newBookPlaceholder="Write your book name"
            newBookLabel="Book Name"
            newbookValue={bookName}
            loading={newBookLoading}
            onNewBookChange={(e) => setBookName(e.target.value)}
          />
        )}

        {addChapter && (
          <PopupPrompt
            divRef={divRef}
            cancelBtnTxt="Cancel"
            cancelOnclick={() => setAddChapter(false)}
            confirmBtnTxt="Save Chapter"
            confirmbtnWidth="140px"
            confirmOnclick={() => handleAddNewChapter()}
            title="Save Chapter"
            newChapterPlaceholder="Select your book name"
            newChapterLabel="Book Name"
            newChapterValue={chapterBook}
            loading={newBookLoading}
            bookOption={optionBookList}
            onNewChapterChange={(e) => setChapterBook(e.target.value)}
            onNewChapterNameChange={(e) => setChapterName(e.target.value)}
            newChapterNamePlaceholder="Enter your chapter name"
            newChapterNameLabel="Chapter Name"
            newChapterNameValue={chapterName}
          />
        )}

        {saveUnit && (
          <PopupPrompt
            divRef={divRef}
            cancelBtnTxt="Cancel"
            cancelOnclick={() => setSaveUnit(false)}
            confirmBtnTxt="Save Topic"
            confirmbtnWidth="140px"
            confirmOnclick={() => addNewUnit()}
            title="Add Chapter"
            newChapterPlaceholder="Select your book name"
            newChapterLabel="Book Name"
            newChapterValue={chapterBook}
            loading={newBookLoading}
            bookOption={optionBookList}
            onNewChapterChange={(e) => setChapterBook(e.target.value)}
            onChapterIdChange={(e) => setChapterId(e.target.value)}
            chapterIdPlaceholder="Select your chapter name"
            chapterIdLabel="Chapter Name"
            chapterIdValue={chapterId}
            chapterOption={chapterListFromBookId}
          />
        )}

        {chapterModal && (
          <PopupPrompt
            ref={divRef}
            confirmBtnTxt="Add Chapter"
            confirmbtnWidth="160px"
            confirmOnclick={() => handleAddChapter()}
            cancelBtnTxt="Close List"
            cancelOnclick={() => setChapterModal(false)}
            title={"Chapter List"}
            chapterData={bookChapterList?.chapters}
          />
        )}
      </GptLayout>
    </Fragment>
  );
};

export default GptSearch;
